declare const require: any;

export const environment = {
    // Default (Dev)
    production: true,
    apiV3Url: "https://api-dev.margaret.app",
    advertisersApiUrl: "https://api-annonceurs-dev.margaret.app",
    defaultRadius: 10000,
    googleMapsApiKey: "AIzaSyCvggQ2UGkRUP56wAqijAJTuOK0uUgm9u8",
    appleClientID: "io.cleverapps.margaret-dev",
    appleRedirectURI: "https://margaret-dev.margaret.app/login",
    shareRootUrl: "https://margaret-dev.margaret.app",
    shareImageUrl: "https://margaret-dev.margaret.app/assets/icon/margaret.png",
    facebookAppId: "356656386476819",
    twitterSite: "",
    desktopVersion: require("../../web-ssr/package.json").version,
};
